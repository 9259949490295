import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Spin, Divider } from 'antd';
import Papa from 'papaparse';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import {
    useCategoryStore,
    usePromotionStore,
    useOfferStore,
    useAuthStore,
    usePromotionGrantStore,
} from '../../contexts/mobx';
import { Promotion, YupPromotion } from '../../stores/promotion';
import moment from 'moment';
import {
    ModalManager,
    NotificationManager,
    NavigationHeader,
    CmsUserHistoryHeader,
} from '../../components';
import style from './style.module.scss';
import * as yup from 'yup';
import get from 'lodash/get';
import produce from 'immer';
import { useNavigation } from '../../utils/use-navigation';
import { Grant, AnonymousGrant } from '../../stores/promotion-grant';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { linkDecorator } from '../../components/rich-text-editor/helper';
import { downloadCSVFile } from '../../utils/csv';
import {
    AcvtCanvas,
    Acvt,
    AcvtBottomTabBar,
    AcvtPromotionPage,
} from '../../components/advanced-ct-visualisation-tool';
import { AcvtSupportedLanguages, AcvtTabs } from '../../types/acvt';
import { ConfigSection } from './components/ConfigSection';
import { ActivationTimeSection } from './components/ActivationTimeSection';
import { OfferDetailsSection } from './components/OfferDetailsSection';
import { ScheduleSection } from './components/ScheduleSection';
import { StatsSection } from './components/StatsSection';
import { WinnableSection } from './components/WinnableSection';
import {
    createPromotion,
    updatePromotion,
    setCategory,
    onDeleteConfirm,
} from '../../utils/promotion';
import { CsvContent } from '../../types';
import { InfoBanner } from '../../components/info-banner';
import { ABTestingSection } from './components/ABTestingSection';

const YupParams = yup.object({
    action: yup.string().oneOf(['create', 'edit', 'duplicate']).required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(v === undefined && action !== 'create');
        }),
});

type Params = yup.InferType<typeof YupParams>;

export type PromotionForm = Omit<Partial<Promotion>, 'items'> & {
    items: {
        title: string;
        subTitle?: string;
        description: EditorState;
        thumbnail?: string | null;
        background?: string | null;
        outsideActivatableHours?: string | null;
        language: 'en' | 'fr';
        previewImageUrl?: string | null;
        previewTitle?: string | null;
    }[];
};

const converter = (promo: Promotion): PromotionForm => {
    return {
        ...promo,
        items: promo.items.map((p) => {
            const { contentBlocks, entityMap } = htmlToDraft(
                p.description ?? ''
            );
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            return {
                ...p,
                description: EditorState.createWithContent(
                    contentState,
                    linkDecorator
                ),
            };
        }),
    };
};

const removeDates = produce((promotion: PromotionForm) => {
    delete promotion.startDate;
    delete promotion.endDate;
    delete promotion.activatableDate;
    delete promotion.inactivatableDate;
});

const hours = [...new Array(24)].map((_, i) => i + 1);

export const PromotionPage = observer(() => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isFetchingAnon, setIsFetchingAnon] = useState(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isCoupon, setIsCoupon] = useState<boolean>(false);
    const store = usePromotionGrantStore();
    const categoryStore = useCategoryStore();
    const promotionStore = usePromotionStore();
    const offerStore = useOfferStore();
    const params = useParams<Params>();
    const navigation = useNavigation();
    const [isUploading, setIsUploading] = useState(false);
    const [grants, setGrants] = useState<Grant[] | null>(null);
    const [anonymousGrants, setAnonymousGrants] = useState<
        AnonymousGrant[] | null
    >(null);
    const [fileSelected, setFileSelected] = useState<boolean>(false);
    const authStore = useAuthStore();
    const hasFetchedPromotion = useRef(false);
    const { hasManagementAccess } = authStore;

    useEffect(() => {
        if (!hasManagementAccess) {
            navigation.goToHome();
        }
    }, [hasManagementAccess, navigation]);

    useEffect(() => {
        if (!YupParams.isValidSync(params)) {
            navigation.replaceToPromotionCreate();
        }
    }, [params, navigation]);

    const formik = useFormik<PromotionForm>({
        initialValues: {
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            id: 0,
            isForMajor: false,
            isForCTConnect: false,
            isForCarwash: false,
            isActive: true,
            isVisibleOnHomePage: true,
            isPrivate: false,
            activationCode: '',
            quantity: 0,
            type: 'in_store',
            activatableDate: null,
            inactivatableDate: null,
            startPeriod: null,
            endPeriod: null,
            category: undefined,
            categoryId: undefined,
            promotionalOffer: null,
            promotionalOfferId: null,
            canBeDeleted: false,
            isNhlGamePromotion: false,
            discountValue: 0,
            originalPrice: 0,
            rebateAmount: 0,
            totalActivations: 0,
            totalGrants: 0,
            hasPreview: false,
            items: [
                {
                    title: '',
                    subTitle: '',
                    description: EditorState.createEmpty(linkDecorator),
                    thumbnail: '',
                    outsideActivatableHours: '',
                    language: 'en',
                    previewImageUrl: '',
                    previewTitle: '',
                },
                {
                    title: '',
                    subTitle: '',
                    description: EditorState.createEmpty(linkDecorator),
                    thumbnail: '',
                    outsideActivatableHours: '',
                    language: 'fr',
                    previewImageUrl: '',
                    previewTitle: '',
                },
            ],
            cmsUserHistory: [],
            inTestingCampaign: false,
        },
        validateOnMount: true,
        validationSchema: YupPromotion,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    useEffect(() => {
        categoryStore.fetchActiveCategories();
    }, [categoryStore]);

    useEffect(() => {
        offerStore.fetchOffers();
    }, [offerStore]);

    const isDuplicate = params.action === 'duplicate';

    useEffect(() => {
        if (
            (params.action === 'edit' || isDuplicate) &&
            !hasFetchedPromotion.current
        ) {
            hasFetchedPromotion.current = true;
            setIsFetching(true);
            promotionStore
                .fetchSinglePromotion(Number(params.id!))
                .then((promotion) => {
                    const modifiedPromotion = isDuplicate
                        ? removeDates(converter(promotion))
                        : converter(promotion);
                    formik.setValues(modifiedPromotion);
                })
                .catch(() => {
                    hasFetchedPromotion.current = false;
                    navigation.replaceToPromotionCreate();
                })
                .finally(() => setIsFetching(false));
        }
    }, [params.action, params.id, promotionStore, navigation]);

    useEffect(() => {
        setIsCoupon(formik.values.type === 'coupon');
    }, [formik.values.type]);

    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path));
        return hasError && (isTouched || params.action !== 'create');
    };

    const handleCreatePromotion = async () => {
        if (formik.values.hasPreview) {
            const missingPreviewImages = formik.values.items.some(
                (item) => !item.previewImageUrl
            );

            if (missingPreviewImages) {
                NotificationManager.showError(
                    'Preview mode is enabled but some languages are missing a preview image'
                );
                return;
            }
        }

        await createPromotion(formik, promotionStore, navigation, authStore);
    };

    const handleUpdatePromotion = async () => {
        if (formik.values.hasPreview) {
            const missingPreviewImages = formik.values.items.some(
                (item) => !item.previewImageUrl
            );

            if (missingPreviewImages) {
                NotificationManager.showError(
                    'Preview mode is enabled but some languages are missing a preview image'
                );
                return;
            }
        }

        await updatePromotion(formik, promotionStore, authStore);
    };

    const handleSetCategory = (categoryId: number) => {
        setCategory(categoryId, formik, categoryStore);
    };

    const handleDeleteConfirm = async () => {
        if (params.id) {
            await onDeleteConfirm(
                { id: params.id },
                setIsDeleting,
                promotionStore,
                navigation
            );
        } else {
            console.error('Promotion ID is undefined');
        }
    };

    const fetchGrants = useCallback(() => {
        setIsFetching(true);
        setIsFetchingAnon(true);
        store
            .fetchGrants(Number(params.id))
            .then(setGrants)
            .catch(console.log)
            .finally(() => setIsFetching(false));

        store
            .fetchAnonymousGrants(Number(params.id))
            .then(setAnonymousGrants)
            .catch(console.log)
            .finally(() => setIsFetchingAnon(false));
    }, [params.id, store]);

    useEffect(() => {
        if (params.action !== 'create') {
            fetchGrants();
        }
    }, [params.action, fetchGrants]);

    const onFileSelected = (file: File) => {
        Papa.parse<unknown>(file, {
            complete: async (results) => {
                try {
                    const emails = results.data.flat().filter((e) => !!e);
                    setIsUploading(true);
                    await store.uploadGrants(Number(params.id), emails);
                    fetchGrants();
                } catch (e) {
                    console.error(e);
                } finally {
                    setIsUploading(false);
                }
            },
            error: () => {
                NotificationManager.showError('Error parsing the file');
            },
        });
        setFileSelected(true);
    };

    const disableUpload = () => {
        const startDate = formik.values.startDate;
        const currentDate = moment();
        return startDate ? currentDate < startDate : false;
    };

    const downloadWinners = () => {
        const flattenGrants: CsvContent =
            grants?.map((grant) => ({
                Type: 'Registered',
                'Grant Id': grant.id,
                'Client Id': grant.clientId,
                Email: grant.client.email,
                'Full Name': grant.client.fullName,
                Language: grant.client.language,
                Count: grant.cnt,
            })) ?? [];
        anonymousGrants?.forEach((anonymousGrant) => {
            flattenGrants?.push({
                Type: 'Anonymous',
                Count: 1,
                Email: anonymousGrant.email,
            });
        });
        return downloadCSVFile(flattenGrants, 'winners');
    };

    const promotionId =
        formik.values.id && params.action !== 'duplicate'
            ? formik.values.id
            : 0;

    const [lang, setLang] = useState<AcvtSupportedLanguages>('en');
    const [acvtItemsIndex, setAcvtItemsIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState<AcvtTabs>('promos');
    const [isCardOpened, setIsCardOpened] = useState({
        homePromoCard: false,
        homeGameCard: false,
        promoCard: true,
        gameCard: false,
    });

    useEffect(() => {
        setAcvtItemsIndex(lang === 'fr' ? 1 : 0);
    }, [lang, formik.values.items]);

    const changeLanguage = (language: AcvtSupportedLanguages) =>
        setLang(language);

    const promoObjectData = {
        id: formik.values.id,
        type: formik.values.type,
        thumbnail: formik.values.items[acvtItemsIndex].thumbnail!,
        background: formik.values.items[acvtItemsIndex].background!,
        category: {
            id: acvtItemsIndex,
            title: formik.values.category?.items[acvtItemsIndex].title,
        },
        title: formik.values.items[acvtItemsIndex].title,
        startDate: formik.values.startDate,
        endDate: formik.values.endDate,
        activatableDate: formik.values.activatableDate,
        inactivatableDate: formik.values.inactivatableDate,
        startPeriod: formik.values.startPeriod,
        endPeriod: formik.values.endPeriod,
        description: draftToHtml(
            convertToRaw(
                formik.values.items[
                    acvtItemsIndex
                ].description.getCurrentContent()
            )
        ),
        promotionalOffer: {
            id: acvtItemsIndex,
            title: formik.values.promotionalOffer?.items[acvtItemsIndex].title,
        },
    };

    const transformId =
        !isDuplicate && params.id ? Number(params.id) : undefined;

    const handleSave = isDuplicate
        ? handleCreatePromotion
        : handleUpdatePromotion;

    return (
        <div className={style.container}>
            <div className={style.page_title_container}>
                <NavigationHeader
                    id={transformId}
                    action={params.action}
                    onBackClick={() => window.history.back()}
                    onSaveClick={handleSave}
                    onCreateClick={handleCreatePromotion}
                    disabled={!formik.isValid}
                    isLoading={promotionStore.isUpsertingPromotion}
                    onDeleteClick={ModalManager.showDeleteModal({
                        onConfirm: handleDeleteConfirm,
                    })}
                    onDuplicateClick={
                        promotionId !== 0
                            ? () =>
                                  navigation.goToPromotionDuplicate(promotionId)
                            : undefined
                    }
                    isDeleting={isDeleting}
                    canBeDeleted={true}
                    type="Promotion"
                />
            </div>
            <form
                style={{
                    display: isFetching ? 'none' : 'flex',
                    flexDirection: 'column',
                    padding: '0.4rem',
                }}
            >
                <div className={style.config_viewer_split}>
                    <div className={style.left_side}>
                        {isDuplicate ? (
                            <InfoBanner
                                message={`You are duplicating the promotion #${params.id}`}
                                description="Any changes you make will create a new promotion and will not affect the original promotion."
                            />
                        ) : (
                            formik.values.cmsUserHistory && (
                                <CmsUserHistoryHeader
                                    cmsUserHistory={
                                        formik.values.cmsUserHistory
                                    }
                                />
                            )
                        )}
                        {isFetching && <Spin />}

                        <h4 className={style.section_header_config}>Config</h4>
                        <ConfigSection
                            values={formik.values}
                            setFieldValue={formik.setFieldValue}
                            handleChange={formik.handleChange}
                            categoryStore={{
                                isFetchingActiveCategories:
                                    categoryStore.isFetchingActiveCategories,
                                activeCategories:
                                    categoryStore.activeCategories,
                            }}
                            offerStore={offerStore}
                            setCategory={handleSetCategory}
                        />

                        <h4 className={style.section_header}>Schedule</h4>
                        <ScheduleSection
                            values={formik.values}
                            setFieldValue={formik.setFieldValue}
                            checkIfHasError={checkIfHasError}
                            fileSelected={fileSelected}
                        />

                        {isCoupon && (
                            <>
                                <h4 className={style.section_header}>
                                    Active time
                                </h4>
                                <ActivationTimeSection
                                    values={formik.values}
                                    setFieldValue={formik.setFieldValue}
                                    checkIfHasError={checkIfHasError}
                                    hours={hours}
                                />
                            </>
                        )}

                        {formik.values.inTestingCampaign && (
                            <>
                                <h4 className={style.section_header}>
                                    AB Testing
                                </h4>
                                <ABTestingSection
                                    originalPrice={
                                        formik.values.originalPrice ?? 0
                                    }
                                    rebateAmount={
                                        formik.values.rebateAmount ?? 0
                                    }
                                    setFieldValue={formik.setFieldValue}
                                    checkIfHasError={checkIfHasError}
                                />
                            </>
                        )}

                        <h4 className={style.section_header}>Details</h4>
                        <OfferDetailsSection
                            values={formik.values as PromotionForm}
                            setFieldValue={formik.setFieldValue}
                            offerStore={{
                                offers: offerStore.offers,
                                isFetchingOffers: offerStore.isFetchingOffers,
                                getOffersById: offerStore.getOffersById.bind(
                                    offerStore
                                ),
                                fetchOffers: offerStore.fetchOffers.bind(
                                    offerStore
                                ),
                                offersMap: offerStore.offersMap,
                            }}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                        />

                        <h4 className={style.section_header}>Stats</h4>
                        <div className={style.full_width_row}>
                            <StatsSection
                                totalGrants={formik.values.totalGrants}
                                totalActivations={
                                    formik.values.totalActivations
                                }
                                discountValue={formik.values.discountValue}
                                isPrivate={formik.values.isPrivate}
                            />
                        </div>

                        <Divider orientation="left" className={style.divider}>
                            Winnable
                        </Divider>
                        <WinnableSection
                            isPrivate={formik.values.isPrivate}
                            setFieldValue={formik.setFieldValue}
                            onFileSelected={onFileSelected}
                            isUploading={isUploading}
                            disableUpload={disableUpload}
                            downloadWinners={downloadWinners}
                            grants={grants}
                            anonymousGrants={anonymousGrants}
                            isFetching={isFetching}
                            isFetchingAnon={isFetchingAnon}
                        />
                    </div>
                    <div className={style.preview_container}>
                        <h4 className={style.section_header}>App Preview</h4>
                        <AcvtCanvas changeLang={changeLanguage}>
                            <Acvt>
                                <>
                                    <AcvtPromotionPage
                                        key={formik.values.id}
                                        language={lang}
                                        promoObject={promoObjectData}
                                        opened={setIsCardOpened}
                                        onEditMode={true}
                                    />
                                    <AcvtBottomTabBar
                                        language={lang}
                                        tab={selectedTab}
                                        tabSelected={setSelectedTab}
                                        onEditMode={true}
                                    />
                                </>
                            </Acvt>
                        </AcvtCanvas>
                    </div>
                </div>
            </form>
        </div>
    );
});
