import * as yup from 'yup';
import { observable, action, toJS, makeObservable } from 'mobx';
import produce from 'immer';
import { RootStore } from './root';
import { YupCategory } from './category';
import { YupOffer } from './offer';
import { MomentSchema, yupCmsHistory } from '../utils/yup-schemas';
import { NotificationManager } from '../components';
import {
    ActiveQueryParam,
    PromotionTypes,
    PromotionTypesRegEx,
} from '../utils/const';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { Lang, LangRegEx } from '../utils';
import { HttpMethod } from '../types';

const promotionItems = yup
    .object({
        title: yup.string().required(),
        subTitle: yup.string(),
        description: yup.mixed(),
        thumbnail: yup.string().nullable(),
        outsideActivatableHours: yup.string().nullable(),
        previewImageUrl: yup
            .string()
            .nullable()
            .test(
                'required-if-preview',
                'Preview image is required when preview mode is enabled',
                function (value) {
                    const hasPreview =
                        this.parent.hasPreview ||
                        this.options.context?.hasPreview;
                    if (!hasPreview) return true;
                    return !!value;
                }
            ),
        previewTitle: yup.string().nullable(),
    })
    .required()
    .shape({
        language: yup.string<Lang>().required().matches(LangRegEx),
    });

export const YupPromotion = yup
    .object({
        activatableDate: new MomentSchema().nullable(),
        inactivatableDate: new MomentSchema().nullable(),
        startDate: new MomentSchema().required(),
        endDate: new MomentSchema().required(),
        startPeriod: yup.number().nullable(),
        endPeriod: yup.number().nullable(),
        deepLink: yup.string().nullable(),
        id: yup.number().required(),
        isForMajor: yup.boolean().required(),
        isHint: yup.boolean().nullable(),
        isForCTConnect: yup.boolean().nullable(),
        isForCarwash: yup.boolean(),
        isActive: yup.boolean().required(),
        isVisibleOnHomePage: yup.boolean().required(),
        isPrivate: yup.boolean().nullable(),
        isNhlGamePromotion: yup.boolean().nullable(),
        canBeDeleted: yup.boolean().required(),
        status: yup.string(),
        discountValue: yup.number().notRequired().nullable(),
        rebateAmount: yup.number().required(),
        originalPrice: yup.number().required(),
        totalActivations: yup.number().notRequired().nullable(),
        totalGrants: yup.number().notRequired().nullable(),
        activationCode: yup
            .string()
            .nullable()
            .test('shouldBeDefined', 'It should be defined', function (v) {
                const type: PromotionTypes = this.resolve(yup.ref('type'));
                return !(type === 'coupon' && !v);
            }),
        quantity: yup
            .number()
            .required()
            .nullable()
            .test('shouldBeDefined', 'It should be defined', function (v) {
                const type: PromotionTypes = this.resolve(yup.ref('type'));
                return !(type === 'coupon' && v == null);
            }),
        activationsCount: yup.number().nullable().default(0),
        type: yup
            .string<PromotionTypes>()
            .matches(PromotionTypesRegEx)
            .required(),
        promotionalOfferId: yup.number().nullable(),
        promotionalOffer: YupOffer.nullable(),
        items: yup.array(promotionItems).required(),
        category: YupCategory.required(),
        categoryId: yup.number().required(),
        cmsUserEmail: yup.string().notRequired().nullable(),
        cmsUserHistory: yup.array(yupCmsHistory).nullable(),
        temperatureActivationFloor: yup.number().nullable(),
        temperatureActivationCeiling: yup.number().nullable(),
        inTestingCampaign: yup.boolean(),
        hasPreview: yup.boolean(),
    })
    .required();

const YupPromotionNetworkList = yup.object({
    page: yup.number().required(),
    perPage: yup.number().required(),
    total: yup.number().required(),
    results: yup.array(YupPromotion).default([]),
});

const YupNotPaginatedList = yup.object({
    total: yup.number().required(),
    results: yup.array(YupPromotion).default([]),
});

const YupVisiblePromotionsList = yup.array(YupPromotion).required();

export type PromotionList = yup.InferType<typeof YupPromotionNetworkList>;
export type Promotion = yup.InferType<typeof YupPromotion>;
export type VisiblePromotionList = yup.InferType<
    typeof YupVisiblePromotionsList
>;

export type PromotionSearchParams = {
    perPage?: number;
    page?: number;
    type?: string;
    filter?: ActiveQueryParam;
    categoryId?: number;
    promotionalOfferId?: number;
    q?: string;
    isPrivate?: string;
};

export class PromotionStore extends WithNetworkConcurrency {
    @observable promotions: PromotionList | null = null;
    @observable inStoreVisiblePromotions: Promotion[] = [];
    @observable couponVisiblePromotions: Promotion[] = [];
    @observable isFetchingCount = 0;
    @observable isFetchingVisiblePromotions = false;
    @observable isUpsertingPromotion = false;
    @observable isUpdatingCouponsOrder = false;
    @observable isUpdatingInStoreOrder = false;
    @observable private cachedPromotion: Promotion | null = null;
    @observable isFetchingForSelect = false;
    @observable promotionsForSelect: Promotion[] = [];
    @observable isDeletingAndRefreshing = false;
    @observable isFetchingForCategory = false;
    @observable promotionsForCategory: Promotion[] = [];
    @observable isExportingPromotions = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    private setPromotions(this: PromotionStore, promotions: PromotionList) {
        this.promotions = promotions;
    }

    @action
    private setVisiblePromotions(
        this: PromotionStore,
        promotions: VisiblePromotionList
    ) {
        this.inStoreVisiblePromotions = promotions.filter(
            (p) => p.type === 'in_store'
        );
        this.couponVisiblePromotions = promotions.filter(
            (p) => p.type === 'coupon'
        );
    }

    @action
    setInStoreVisiblePromotions(this: PromotionStore, promotions: Promotion[]) {
        this.inStoreVisiblePromotions = promotions;
    }

    @action
    setCouponVisiblePromotions(this: PromotionStore, promotions: Promotion[]) {
        this.couponVisiblePromotions = promotions;
    }

    @action
    setCachedPromotion(this: PromotionStore, promotion: Promotion) {
        this.cachedPromotion = promotion;
    }

    @action
    async fetchPromotions(
        this: PromotionStore,
        searchParams: PromotionSearchParams
    ) {
        const tag = this.getTag();
        this.isFetchingCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotions',
                params: searchParams,
            },
            YupPromotionNetworkList
        );
        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.setPromotions(response.data);
        }
    }

    @action
    async fetchForSelect(this: PromotionStore) {
        this.isFetchingForSelect = true;
        /* const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: "/promotions",
                params: { all: true, filter: "active" },
            },
            YupNotPaginatedList
        ); */
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotions/visible',
            },
            YupVisiblePromotionsList
        );
        this.isFetchingForSelect = false;

        if (!response.err) {
            this.promotionsForSelect = response.data;
        }
    }

    @action
    async fetchForCategory(this: PromotionStore, categoryId: number) {
        this.isFetchingForCategory = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotions',
                params: { all: true, categoryId, filter: 'active' },
            },
            YupNotPaginatedList
        );
        this.isFetchingForCategory = false;

        if (!response.err) {
            this.promotionsForCategory = response.data.results;
        }
    }

    @action
    async fetchVisiblePromotions(this: PromotionStore) {
        if (this.isFetchingVisiblePromotions) {
            return;
        }

        this.isFetchingVisiblePromotions = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotions/visible',
            },
            YupVisiblePromotionsList
        );
        this.isFetchingVisiblePromotions = false;

        if (!response.err) {
            this.setVisiblePromotions(response.data);
        }
    }

    @action
    async fetchSinglePromotion(
        this: PromotionStore,
        promotionId: number
    ): Promise<Promotion> {
        if (promotionId === this.cachedPromotion?.id) {
            return toJS(this.cachedPromotion);
        }

        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: `/promotions/${promotionId}`,
            },
            YupPromotion
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async createPromotion(this: PromotionStore, promotion: Promotion) {
        const cleanPromotion = produce(promotion, (draft) => {
            delete (draft as any).id;
        });

        this.isUpsertingPromotion = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            data: cleanPromotion,
            url: `/promotions`,
        });
        this.isUpsertingPromotion = false;

        if (!response.err) {
            NotificationManager.showSuccess('Promotion created');
        }

        return !response.err;
    }

    @action
    async modifyPromotion(this: PromotionStore, promotion: Promotion) {
        this.isUpsertingPromotion = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            data: promotion,
            url: `/promotions/${promotion.id}`,
        });
        this.isUpsertingPromotion = false;

        if (!response.err) {
            NotificationManager.showSuccess('Promotion modified');
        }
    }

    @action
    async updateVisibleCouponsOrder(this: PromotionStore) {
        if (this.isUpdatingCouponsOrder) {
            return;
        }

        const data = {
            promotionIds: this.couponVisiblePromotions.map((p) => p.id),
        };

        this.isUpdatingCouponsOrder = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PATCH,
            url: `/promotions/sort`,
            data,
        });
        this.isUpdatingCouponsOrder = false;

        if (!response.err) {
            NotificationManager.showSuccess('Coupons order modified');
        }
    }

    @action
    async updateVisibleInStoreOrder(this: PromotionStore) {
        if (this.isUpdatingInStoreOrder) {
            return;
        }

        const data = {
            promotionIds: this.inStoreVisiblePromotions.map((p) => p.id),
        };

        this.isUpdatingInStoreOrder = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PATCH,
            url: `/promotions/sort`,
            data,
        });
        this.isUpdatingInStoreOrder = false;

        if (!response.err) {
            NotificationManager.showSuccess('In store order modified');
        }
    }

    @action
    async delete(this: PromotionStore, promotionId: number) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/promotions/${promotionId}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Promotion deleted');
        }

        return response;
    }

    @action
    async deleteAndRefreshList(
        this: PromotionStore,
        promotionId: number,
        params: PromotionSearchParams
    ) {
        if (this.isDeletingAndRefreshing) {
            return;
        }

        this.isDeletingAndRefreshing = true;
        const response = await this.delete(promotionId);
        if (!response.err) {
            await this.fetchPromotions(params);
        }
        this.isDeletingAndRefreshing = false;
    }

    async exportPromotions(this: PromotionStore): Promise<void> {
        if (this.isExportingPromotions) {
            return;
        }

        this.isExportingPromotions = true;

        try {
            const baseUrl = process.env.REACT_APP_API_URL || '';
            const url = `${baseUrl}/api-cms/promotions/export`;

            const headers = this.rootStore.getHeaders();

            const response = await fetch(url, {
                method: 'GET',
                headers,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(
                    `Export failed with status: ${response.status} - ${errorText}`
                );
            }

            const blob = await response.blob();

            const downloadUrl = window.URL.createObjectURL(
                new Blob([blob], { type: 'text/csv;charset=UTF-8' })
            );

            const contentDisposition = response.headers.get(
                'content-disposition'
            );
            const filenameMatch = contentDisposition?.match(
                /filename="?([^"]+)"?/
            );
            const filename = filenameMatch
                ? filenameMatch[1]
                : `promotions-export-${new Date()
                      .toISOString()
                      .slice(0, 10)}.csv`;

            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = downloadUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            setTimeout(() => {
                window.URL.revokeObjectURL(downloadUrl);
                document.body.removeChild(link);
            }, 100);

            NotificationManager.showSuccess('Promotions exported successfully');
        } catch (error) {
            console.error('Export error:', error);

            if (error instanceof Error) {
                NotificationManager.showError(
                    `Failed to export promotions: ${error.message}`
                );
            } else {
                NotificationManager.showError('Failed to export promotions');
            }
        } finally {
            this.isExportingPromotions = false;
        }
    }
}
