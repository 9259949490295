import React from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import style from '../../style.module.scss';
import StatsBox from '../../../../components/stats-box';
import { useOnboardingStore } from '../../../../contexts/mobx';
import { friendlyNames, iconsMap, calculateDifference } from '../../utils';

export const OnboardingStepsSection = observer(() => {
    const onboardingStore = useOnboardingStore();

    return (
        <div className={style.section_container}>
            <div className={style.main_container}>
                <div className={style.box_container}>
                    <h1 className={style.section_title}>Onboarding Steps</h1>
                    {onboardingStore.isFetchingOnboardingData ? (
                        <Spin />
                    ) : (
                        <div className={style.row}>
                            {onboardingStore.onboardingData.map(
                                (step, index) => {
                                    const displayTitle =
                                        friendlyNames[
                                            step.onboardingStepName
                                        ] || step.onboardingStepName;
                                    const IconComponent =
                                        iconsMap[step.onboardingStepName] ||
                                        iconsMap['IN_PROGRESS'];
                                    const previousStep = onboardingStore.onboardingDataPrevious.find(
                                        (prev) =>
                                            prev.onboardingStepName ===
                                            step.onboardingStepName
                                    );
                                    const previousTotal = previousStep
                                        ? previousStep.completedCount
                                        : 0;
                                    const differenceVal = calculateDifference(
                                        step.completedCount,
                                        previousTotal
                                    );

                                    return (
                                        <StatsBox
                                            key={index}
                                            title={displayTitle}
                                            currentValue={step.completedCount}
                                            difference={differenceVal}
                                            icon={IconComponent}
                                        />
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
