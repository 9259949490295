import React from 'react';
import { observer } from 'mobx-react';
import { Table, Tag, Select, Popover, Button, Input, Progress } from 'antd';
import { LegacyPagination, ModalManager } from '../../components';
import {
    usePromotionStore,
    useCategoryStore,
    useOfferStore,
    useAuthStore,
} from '../../contexts/mobx';
import { Promotion, PromotionSearchParams } from '../../stores/promotion';
import { ColumnProps } from 'antd/es/table';
import { useQueryParams } from '../../utils/use-query-params';
import style from './style.module.scss';
import {
    getPromotionsTypeDisplay,
    getPromotionsTypeName,
} from '../../utils/promotion-types';
import { getPromotionStatus } from '../../utils/promotion-status';
import { PromotionTypes, ActiveQueryParam } from '../../utils/const';
import { useNavigation } from '../../utils/use-navigation';
import { getItemProp } from '../../utils';
import { DownloadOutlined } from '@ant-design/icons';

const renderTitle = (_: unknown, record: Promotion) => {
    const enTitle = getItemProp(record.items, 'en', 'title');
    return <div className={style.title}>{enTitle}</div>;
};

const renderSubtitle = (_: unknown, record: Promotion) => {
    const enSubtitle = getItemProp(record.items, 'en', 'subTitle');
    return <div className={style.title}>{enSubtitle}</div>;
};

const renderThumbnail = (_: unknown, record: Promotion) => {
    const enImgSrc = getItemProp(record.items, 'en', 'thumbnail');
    return <img key="en" src={enImgSrc ?? ''} alt="" className={'thumbnail'} />;
};

const renderDates = (_: unknown, record: Promotion) => {
    return (
        <div className="dates_holder">
            <div className="text_center inline_block">
                <div className="light_label">
                    Start
                    <div className="vr"></div>
                </div>
                <div>{record.startDate.format('ll')}</div>
                <div className="lil_label">{record.startDate.format('LT')}</div>
            </div>
            {record.activatableDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        Activatable
                        <div className="vr"></div>
                    </div>
                    <div>{record.activatableDate.format('ll')}</div>
                    <div className="lil_label">
                        {record.activatableDate.format('LT')}
                    </div>
                </div>
            )}
            {record.inactivatableDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        Inactivatable
                        <div className="vr"></div>
                    </div>
                    <div>{record.inactivatableDate.format('ll')}</div>
                    <div className="lil_label">
                        {record.inactivatableDate.format('LT')}
                    </div>
                </div>
            )}
            {record.endDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        End
                        <div className="vr"></div>
                    </div>
                    <div>{record.endDate.format('ll')}</div>
                    <div className="lil_label">
                        {record.endDate.format('LT')}
                    </div>
                </div>
            )}
        </div>
    );
};

const renderIsActive = (_: unknown, record: Promotion) => {
    const promotionDateStatus = getPromotionStatus(record);
    let activationsPercent = 0;
    if (
        record.activationsCount !== null &&
        record.quantity !== null &&
        record.quantity !== 0
    ) {
        activationsPercent = +(
            (record.activationsCount / record.quantity) *
            100
        ).toFixed(2);
    }

    return (
        <div>
            {record.isActive ? (
                <Tag color="#87d068">Active</Tag>
            ) : (
                <Tag color="#ee1313">Inactive</Tag>
            )}
            {promotionDateStatus !== null && (
                <Tag className={style.date_status} color="#f0f0f0">
                    {promotionDateStatus}
                </Tag>
            )}
            {record.type === 'coupon' && (
                <div className={style.activations_count}>
                    {record.activationsCount} of {record.quantity} activations
                    <Progress percent={activationsPercent} />
                </div>
            )}
        </div>
    );
};

const renderType = (_: unknown, record: Promotion) => {
    return getPromotionsTypeDisplay(record.type);
};

const renderCategory = (_: unknown, record: Promotion) => {
    const enTitle = getItemProp(record.category?.items, 'en', 'title');
    return <Tag color="blue">{enTitle}</Tag>;
};

const renderOffer = (_: unknown, record: Promotion) => {
    return (
        <div>
            {record.promotionalOffer?.items != null && (
                <Tag color="purple">
                    {getItemProp(record.promotionalOffer?.items, 'en', 'title')}
                </Tag>
            )}
        </div>
    );
};

const renderOtherOptions = (
    onDuplicate: (record: Promotion) => void,
    onEdit: (record: Promotion) => void,
    onDelete: (record: Promotion) => void,
    onViewWinners: (record: Promotion) => void
) => {
    const OtherOptions = (_: unknown, record: Promotion) => {
        const text = <span>More option</span>;
        const content = (
            <div className={style.other_options}>
                <p onClick={() => onEdit(record)}>Edit</p>
                <p onClick={() => onDuplicate(record)}>Duplicate</p>
                {record.isPrivate && (
                    <p onClick={() => onViewWinners(record)}>View winners</p>
                )}
                {record.canBeDeleted && (
                    <p
                        onClick={() => onDelete(record)}
                        className={style.delete_link}
                    >
                        Delete
                    </p>
                )}
            </div>
        );

        return (
            <Popover
                placement="left"
                title={text}
                content={content}
                trigger="click"
            >
                <Button>...</Button>
            </Popover>
        );
    };
    OtherOptions.displayName = 'OtherOptions';
    return OtherOptions;
};

export const PromotionsPage = observer(() => {
    const promotionStore = usePromotionStore();
    const categoryStore = useCategoryStore();
    const offerStore = useOfferStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    const [
        queryParams,
        updateQueryParams,
        updateSearchParams,
        updateManyParamsAndGoToFirstPage,
    ] = useQueryParams<PromotionSearchParams>();

    React.useEffect(() => {
        promotionStore.fetchPromotions(queryParams);
    }, [queryParams, promotionStore]);

    React.useEffect(() => {
        categoryStore.fetchActiveCategories();
    }, [categoryStore]);

    React.useEffect(() => {
        offerStore.fetchOffers();
    }, [offerStore]);

    const onDuplicate = (record: Promotion) => {
        promotionStore.setCachedPromotion(record);
        navigation.goToPromotionDuplicate(record.id);
    };

    const onEdit = (record: Promotion) => {
        promotionStore.setCachedPromotion(record);
        navigation.goToPromotionEdit(record.id);
    };

    const onDelete = (record: Promotion) => {
        ModalManager.showDeleteModal({
            onConfirm: () =>
                promotionStore.deleteAndRefreshList(record.id, queryParams),
        })();
    };

    const onViewWinners = (record: Promotion) => {
        navigation.goToGrants(record.id);
    };

    const handleExportPromotions = async () => {
        await promotionStore.exportPromotions();
    };

    const columns: ColumnProps<Promotion>[] = [
        {
            key: 'is_active',
            render: renderIsActive,
        },
        {
            key: 'image',
            render: renderThumbnail,
        },
        {
            title: 'Title',
            key: 'title',
            render: renderTitle,
        },
        {
            title: 'Subtitle',
            key: 'subtitle',
            render: renderSubtitle,
        },
        {
            title: 'Offer',
            key: 'offer',
            render: renderOffer,
        },
        {
            title: 'Type',
            key: 'type',
            render: renderType,
        },
        {
            title: 'Category',
            key: 'category',
            render: renderCategory,
        },
        {
            title: 'Dates',
            key: 'dates',
            render: renderDates,
        },
    ];

    const clearFilters = () => {
        updateManyParamsAndGoToFirstPage({
            q: undefined,
            type: undefined,
            filter: undefined,
            categoryId: undefined,
            promotionalOfferId: undefined,
            isPrivate: undefined,
        });
    };

    return (
        <div>
            <div
                className={style.header_container}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h1>Promotions</h1>
                <div className={style.action_buttons}>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleExportPromotions}
                        loading={promotionStore.isExportingPromotions}
                        style={{ marginRight: '10px' }}
                    >
                        Export to CSV
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => navigation.goToPromotionCreate()}
                    >
                        Create new
                    </Button>
                </div>
            </div>

            <div className={`${style.selects_row} top_spacer`}>
                <Input
                    value={queryParams.q}
                    onChange={(e) => updateSearchParams('q', e.target.value)}
                    allowClear={true}
                    placeholder="Search"
                />
                <Select
                    placeholder="Type"
                    value={queryParams.type}
                    onChange={(v: string | undefined) =>
                        updateSearchParams('type', v)
                    }
                    allowClear
                >
                    <Select.Option value={PromotionTypes[0]}>
                        {getPromotionsTypeName(PromotionTypes[0])}
                    </Select.Option>
                    <Select.Option value={PromotionTypes[1]}>
                        {getPromotionsTypeName(PromotionTypes[1])}
                    </Select.Option>
                </Select>
                <Select<ActiveQueryParam>
                    placeholder="Status"
                    value={queryParams.filter}
                    onChange={(v: ActiveQueryParam | undefined) =>
                        updateSearchParams('filter', v)
                    }
                    allowClear
                >
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                </Select>
                <Select
                    placeholder="Category"
                    defaultValue={undefined}
                    onChange={(id: number | undefined) =>
                        updateSearchParams('categoryId', id)
                    }
                    value={queryParams.categoryId}
                    allowClear
                    loading={categoryStore.isFetchingActiveCategories}
                >
                    {categoryStore.activeCategories?.results.map((category) => (
                        <Select.Option value={category.id} key={category.id}>
                            {category.items[0].title}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder="Promotional Offer"
                    defaultValue={undefined}
                    onChange={(id: number | undefined) =>
                        updateSearchParams('promotionalOfferId', id)
                    }
                    value={queryParams.promotionalOfferId}
                    allowClear
                    loading={offerStore.isFetchingOffers}
                >
                    {offerStore.offers?.results.map((offers) => (
                        <Select.Option value={offers.id} key={offers.id}>
                            {offers.items[0].title}
                        </Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder="Winnable"
                    value={queryParams.isPrivate}
                    onChange={(e: string | undefined) =>
                        updateSearchParams('isPrivate', e)
                    }
                    allowClear
                >
                    <Select.Option value="yes">
                        Winnable promotions
                    </Select.Option>
                    <Select.Option value="no">Normal promotions</Select.Option>
                </Select>
                <Button type="primary" onClick={() => clearFilters()}>
                    Clear Filters
                </Button>
            </div>
            <LegacyPagination
                params={queryParams}
                meta={promotionStore.promotions}
                onChange={(pageNumber) =>
                    updateQueryParams({ page: pageNumber })
                }
                onPageSizeChange={(size) =>
                    updateQueryParams({ perPage: size })
                }
            />
            <Table<Promotion>
                className={`${style.table} clickable_table`}
                scroll={{ x: 900 }}
                rowKey="id"
                dataSource={promotionStore.promotions?.results}
                columns={columns}
                loading={promotionStore.isFetchingCount > 0}
                pagination={false}
                onRow={(r) => ({
                    onClick: () => navigation.goToPromotionEdit(r.id),
                })}
            />
        </div>
    );
});
