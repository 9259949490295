import React from 'react';
import { Alert } from 'antd';

interface PreviewValidationHelperProps {
    hasPreview: boolean | undefined;
    items: {
        language: string;
        previewImageUrl?: string | null;
    }[];
}

export const PreviewValidationHelper: React.FC<PreviewValidationHelperProps> = ({
    hasPreview,
    items,
}) => {
    if (!hasPreview) return null;

    const missingImageLanguages = items
        .filter((item) => !item.previewImageUrl)
        .map((item) => item.language.toUpperCase());

    if (missingImageLanguages.length === 0) return null;

    return (
        <Alert
            message="Preview Image Required"
            description={`Preview mode is enabled but the following languages are missing a preview image: ${missingImageLanguages.join(
                ', '
            )}`}
            type="info"
            showIcon
            style={{ marginBottom: '1rem' }}
        />
    );
};
