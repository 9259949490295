import React, { useEffect } from 'react';
import { Input, Card } from 'antd';
import style from './style.module.scss';
import {
    ErrorAwareLabel,
    ImageInput,
    RichTextEditor,
} from '../../../../components';
import { RichProvider } from '../../../../components/rich-text-editor/helper';
import { PromotionForm } from '../..';
import { PreviewValidationHelper } from './PreviewValidationHelper';

interface Offer {
    id: number;
    items: {
        title?: string;
        language: string;
    }[];
}

interface OfferDetailsSectionProps {
    values: PromotionForm;
    setFieldValue: (field: string, value: unknown) => void;
    offerStore: {
        offers: { results: Offer[] } | null;
        isFetchingOffers: boolean;
        getOffersById: (id: number) => Offer | undefined;
        fetchOffers: () => Promise<void>;
        offersMap: Map<number, Offer>;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const OfferDetailsSection = ({
    values,
    setFieldValue,
    offerStore,
    handleChange,
    handleBlur,
}: OfferDetailsSectionProps) => {
    useEffect(() => {
        offerStore.fetchOffers();
    }, [offerStore]);

    return (
        <Card className={style.card_container}>
            <PreviewValidationHelper
                hasPreview={values.hasPreview}
                items={values.items}
            />
            <div className={style.language_section}>
                {values.items.map((item, i) => (
                    <div key={item.language} className={style.details_box}>
                        <h2>{item.language.toUpperCase()}</h2>

                        <div className={style.image_uploaders}>
                            <div>
                                <ErrorAwareLabel
                                    text="Foreground"
                                    className={style.bold_label}
                                />
                                <ImageInput
                                    url={item.thumbnail}
                                    onChange={(url) =>
                                        setFieldValue(
                                            `items[${i}].thumbnail`,
                                            url
                                        )
                                    }
                                    onDelete={() =>
                                        setFieldValue(
                                            `items[${i}].thumbnail`,
                                            null
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <ErrorAwareLabel
                                    text="Background"
                                    className={style.bold_label}
                                />
                                <ImageInput
                                    url={item.background}
                                    onChange={(url) =>
                                        setFieldValue(
                                            `items[${i}].background`,
                                            url
                                        )
                                    }
                                    onDelete={() =>
                                        setFieldValue(
                                            `items[${i}].background`,
                                            null
                                        )
                                    }
                                />
                            </div>
                            {values.hasPreview && (
                                <div>
                                    <ErrorAwareLabel
                                        text="Preview Image *"
                                        className={style.bold_label}
                                    />
                                    <ImageInput
                                        url={item.previewImageUrl || null}
                                        onChange={(url) =>
                                            setFieldValue(
                                                `items[${i}].previewImageUrl`,
                                                url
                                            )
                                        }
                                        onDelete={() =>
                                            setFieldValue(
                                                `items[${i}].previewImageUrl`,
                                                null
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        <div>
                            <ErrorAwareLabel
                                text="Title"
                                className={style.bold_label}
                            />
                            <Input
                                name={`items[${i}].title`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={item.title}
                                style={{ width: '100%', marginBottom: 10 }}
                            />

                            <ErrorAwareLabel
                                text="Sub Title"
                                className={style.bold_label}
                            />
                            <Input
                                name={`items[${i}].subTitle`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={item.subTitle}
                                style={{ width: '100%', marginBottom: 10 }}
                            />

                            {values.hasPreview && (
                                <>
                                    <ErrorAwareLabel
                                        text="Preview Title"
                                        className={style.bold_label}
                                    />
                                    <Input
                                        name={`items[${i}].previewTitle`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={item.previewTitle || ''}
                                        style={{
                                            width: '100%',
                                            marginBottom: 10,
                                        }}
                                    />
                                </>
                            )}

                            <ErrorAwareLabel
                                text="Description"
                                className={style.bold_label}
                            />
                            <RichProvider>
                                <RichTextEditor
                                    onEditorStateChange={(x) =>
                                        setFieldValue(
                                            `items[${i}].description`,
                                            x
                                        )
                                    }
                                    editorState={item.description}
                                />
                            </RichProvider>
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
};
