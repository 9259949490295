import React from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import style from '../../style.module.scss';
import StatsBox from '../../../../components/stats-box';
import { useOnboardingStore } from '../../../../contexts/mobx';
import { calculateDifference, iconsMap } from '../../utils';

export const SegmentationSurveySection = observer(() => {
    const onboardingStore = useOnboardingStore();

    return (
        <div className={style.section_container}>
            <div className={style.main_container}>
                <div className={style.box_container}>
                    <h1 className={style.section_title}>Segmentation Survey</h1>
                    {onboardingStore.isFetchingSegmentationData ? (
                        <Spin />
                    ) : (
                        <div className={style.row}>
                            {onboardingStore.segmentationData.map(
                                (segment, index) => {
                                    const previousSegment = onboardingStore.segmentationDataPrevious.find(
                                        (prev) =>
                                            prev.segmentationId ===
                                            segment.segmentationId
                                    );
                                    const previousTotal = previousSegment
                                        ? previousSegment.recordCount
                                        : 0;
                                    const differenceVal = calculateDifference(
                                        segment.recordCount,
                                        previousTotal
                                    );
                                    const IconComponent =
                                        iconsMap[segment.name] ||
                                        iconsMap['user_segmentation_setup'];

                                    return (
                                        <StatsBox
                                            key={index}
                                            title={segment.name}
                                            currentValue={segment.recordCount}
                                            difference={differenceVal}
                                            icon={IconComponent}
                                        />
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
