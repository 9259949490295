import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import produce from 'immer';
import * as yup from 'yup';
import { action, makeObservable } from 'mobx';
import { NotificationManager } from '../components';
import { UserStore } from './user';
import { ConfigStore } from './config';
import { PromotionStore } from './promotion';
import { CategoryStore } from './category';
import { OfferStore } from './offer';
import { FeaturedStore } from './featured';
import { ClientStore } from './client';
import { VisitStore } from './visit';
import { FeedbackStore } from './feedbacks';
import { NPSStore } from './nps';
import { PromotionGrantStore } from './promotion-grant';
import { StoresStore } from './stores';
import { GamesStore } from './games';
import { SmsHistoryStore } from './sms-history';
import { AnalyticsStore } from './analytics';
import { NhlGameStore } from './nhl-game';
import { oktaAuth } from '../App';
import { AuthStore } from './auth';
import { AcvtStore } from './acvt';
import { CareerStore } from './career';
import { RechargeStore } from './recharge';
import { CarWashStore } from './car-wash';
import { OnboardingStore } from './onboarding';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const isAxiosError = (error: any): error is AxiosError => {
    return error && error.isAxiosError;
};

export class RootStore {
    public userStore: UserStore;
    public configStore: ConfigStore;
    public promotionStore: PromotionStore;
    public categoryStore: CategoryStore;
    public offerStore: OfferStore;
    public featuredStore: FeaturedStore;
    public clientStore: ClientStore;
    public visitStore: VisitStore;
    public feedbackStore: FeedbackStore;
    public npsStore: NPSStore;
    public promotionGrantStore: PromotionGrantStore;
    public storesStore: StoresStore;
    public gamesStore: GamesStore;
    public smsHistoryStore: SmsHistoryStore;
    public AnalyticsStore: AnalyticsStore;
    public nhlGamesStore: NhlGameStore;
    public authStore = new AuthStore();
    public acvtStore: AcvtStore;
    public careerStore: CareerStore;
    public rechargeStore: RechargeStore;
    public carWashStore: CarWashStore;
    public onboardingStore: OnboardingStore;

    private cancelSource = axios.CancelToken.source();

    constructor() {
        makeObservable(this);
        this.userStore = new UserStore(this);
        this.configStore = new ConfigStore(this);
        this.promotionStore = new PromotionStore(this);
        this.categoryStore = new CategoryStore(this);
        this.offerStore = new OfferStore(this);
        this.featuredStore = new FeaturedStore(this);
        this.clientStore = new ClientStore(this);
        this.visitStore = new VisitStore(this);
        this.feedbackStore = new FeedbackStore(this);
        this.npsStore = new NPSStore(this);
        this.promotionGrantStore = new PromotionGrantStore(this);
        this.storesStore = new StoresStore(this);
        this.gamesStore = new GamesStore(this);
        this.smsHistoryStore = new SmsHistoryStore(this);
        this.AnalyticsStore = new AnalyticsStore(this);
        this.nhlGamesStore = new NhlGameStore(this);
        this.acvtStore = new AcvtStore(this);
        this.careerStore = new CareerStore(this);
        this.rechargeStore = new RechargeStore(this);
        this.carWashStore = new CarWashStore(this);
        this.onboardingStore = new OnboardingStore(this);
    }

    /**
     * Get standard headers for API requests including authentication and language
     * @param additionalHeaders Optional additional headers to include in the request
     * @param lang Language code (defaults to 'en')
     * @returns Headers object with JWT token and other standard headers
     */
    getHeaders(
        additionalHeaders?: Record<string, string>,
        lang = 'en'
    ): Record<string, string> {
        const token = oktaAuth.getAccessToken();
        const headers: Record<string, string> = {
            'accept-language': lang,
            ...additionalHeaders,
        };

        if (token) {
            headers.jwt = token;
        }

        return headers;
    }

    /**
     * Helper method to wrap all the chores associated with networks requests.
     *  • JWT management
     *  • Error handling
     *  • Base url
     *  • Payload validation
     *
     * @param this
     * @param opts
     * @param schema
     */
    @action
    async makeNetworkCall<NetworkResponse>(
        this: RootStore,
        opts: AxiosRequestConfig,
        schema?: yup.Schema<NetworkResponse>,
        validationOptions?: yup.ValidateOptions
    ): Promise<
        { data: NetworkResponse; err: null } | { data: null; err: true }
    > {
        try {
            const baseHeaders = this.getHeaders();

            const augmentedOpts = produce(opts, (draft) => {
                draft.headers = {
                    ...baseHeaders,
                    ...(draft.headers || {}),
                };

                draft.baseURL = `${REACT_APP_API_URL}/api-cms`;
                draft.cancelToken = this.cancelSource.token;
            });

            const response = await axios.request<NetworkResponse>(
                augmentedOpts
            );

            if (schema) {
                const validatedData = await schema.validate(
                    response.data,
                    validationOptions
                );
                return { data: validatedData, err: null };
            }
            return { data: response.data, err: null };
        } catch (e) {
            console.error(e);
            if (isAxiosError(e)) {
                const status = e.response?.status;
                const code = e.response?.data?.code as string | undefined;

                if (status === 401) {
                    NotificationManager.showError(e.response?.data?.message);
                    this.cancelSource.cancel();
                    oktaAuth.signOut();
                } else if (
                    code === 'promotion_cannot_be_deleted' ||
                    code === 'nhl_game_cannot_be_deleted'
                ) {
                    NotificationManager.showError(
                        `${e.response?.data?.details}`,
                        8
                    );
                } else {
                    NotificationManager.showError(e.response?.data?.message);
                }
            } else if (!axios.isCancel(e)) {
                NotificationManager.showError('Unexpected error');
            }
            return { data: null, err: true };
        }
    }

    @action
    async makeMobileNetworkCall<NetworkResponse>(
        this: RootStore,
        opts: AxiosRequestConfig,
        lang: string
    ): Promise<
        { data: NetworkResponse; err: null } | { data: null; err: true }
    > {
        try {
            const baseHeaders = this.getHeaders({}, lang);

            const augmentedOpts = produce(opts, (draft) => {
                draft.headers = {
                    ...baseHeaders,
                    ...(draft.headers || {}),
                };

                draft.baseURL = `${REACT_APP_API_URL}/api-mobile`;
            });

            const response = await axios.request<NetworkResponse>(
                augmentedOpts
            );

            return { data: response.data, err: null };
        } catch (e) {
            console.error(e);
            if (isAxiosError(e)) {
                NotificationManager.showError(e.response?.data?.message);
            } else if (!axios.isCancel(e)) {
                NotificationManager.showError('Unexpected error');
            }
            return { data: null, err: true };
        }
    }
}
